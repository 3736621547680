import { Component } from 'react';
import './MainWindow.css';
import NewsTicker from './NewsTicker';
import StockTicker from './StockTicker';
import Banner from './Banner';
//import {Button} from '@nextui-org/react'; 

class MainWindow extends Component {
  //<ins class="easa4e4x9u02" data-zoneid="15736" data-keywords="news finance"></ins> 
  //<p>{String(this.state.news[0].title)}</p>
  constructor(props) {
    super(props)
    this.state = {
        index: 0,
    }
  }    

  componentDidMount() {
    this.fetchData();
    // setInterval(() => {
    //   this.fetchData();
    // }, 60000);
    setInterval(() => {
      window.location.reload();
      }, 60000);
  }

  fetchData() {
    fetch('https://linux-finance-ticker.online/fin/rest/latestNews')
        .then(response => response.json())
        .then(data => this.setState({ news: data }));

    fetch('https://linux-finance-ticker.online/fin/rest/latestGainers')
      .then(response => response.json())
      .then(data => this.setState({ stocks: data.data.data }));
    
    console.log("Refresh data");
  }

  render() {
    if ((!this.state) || (!this.state.news)) {
      return;
    }
    //console.log(this.state.news)
    return (
      <main classname="dark text-foreground bg-background">
          <div className="MainWindow">
            <Banner></Banner>
            <div className="Content">
              <StockTicker stocks={this.state.stocks} />    
              <NewsTicker news={this.state.news} />    
            </div>
            <div className="Lower ad">
            </div>      
            <div className="filler">
            </div>
          </div>
      </main>

    
  );
  }
}

export default MainWindow;
