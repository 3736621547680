import { useState, useEffect } from 'react';
import './MainWindow.css';
//import {Button} from '@nextui-org/react'; 

function NewsTicker ({news}) {
    const [index, setIndex] = useState(0);  

    useEffect(() => {
        
        //set timer for news cycle
        const timerId = setInterval(() => setIndex( incIndex()), 5000);      
        return () => clearInterval(timerId);
    });

    function filterTheNews(news) {
          //filter news
          const banned = [ "independent.co.uk",
          "prnewswire.com",
          "screenrant.com",
          "theguardian.com",
          "nj.com",
          "globenewswire.com",
          ];
          var newNews = [];
          for (let  newsItem of news) {
              if (banned.includes(newsItem.source)) {
                  continue;
              }
              else {
                  newNews.push(newsItem);
              }
          }
        //   console.log(newNews);
          return newNews;
    }

    function incIndex() {
        var newIndex = index + 1;
        return newIndex;
    }

    
    function getNews() { 
        if (!news) {
        return;
        }
        const filteredNews = filterTheNews(news);
        if(index >= filteredNews.length)
        {
            setIndex(0);
        }
        
        const final = [];
        var i = 0;
        for (let  newsItem of filteredNews) {
            
            var visClass = 'hiddenNewsItem'
            if (i === index) {
                visClass = ''
            }
            i = i + 1;
            final.push(<div key={i} className={"NewsItem " + visClass}>
                <a href={newsItem.url} className="NewsTitle">{newsItem.title}</a>
                <p>{newsItem.description}</p>
                <a href={"https://www."+newsItem.source}>{newsItem.source}</a>
            </div>);  
        }
        return final;
    }

  return (
    <div className="NewsTicker">
    {getNews()}
    </div>
  );
}

export default NewsTicker;
