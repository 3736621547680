import { useState, useEffect } from 'react';
import './MainWindow.css';
//import {Button} from '@nextui-org/react'; 

function StockTicker ({stocks}) {
    const [index, setIndex] = useState(0);  

    function incIndex() {
        var newIndex = index + 5;
        if(newIndex > stocks.length)
        {
            newIndex = 0;
        }
        return newIndex;
    }

    useEffect(() => {
        const timerId = setInterval(() => setIndex(incIndex()), 5000);
      
        return () => clearInterval(timerId);
    });

    function getNews() { 
        
        if (!stocks) {
        return;
        }
        //console.log(stocks)

        const final = [];
        var i = 0;
        for (let  stock of stocks) {
            //var visClass = ''
            var visClass = 'StockPos'
            if (stock.change < 0) {
                visClass = 'StockNeg'
            }
            i = i + 1;

            final.push(<span key={i} className={"TickerItem"}>
                {stock.s}: <span className={visClass}>{stock.change}</span>%
            </span>);  
        }
        return final;
    }

  return (
    <div className="StockTicker">
    {getNews()}
    </div>
  );
}

export default StockTicker;
