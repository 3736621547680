import './Home.css'
import { useState} from 'react';
import FsLightbox from "fslightbox-react";

function Home() {
    const [toggler, setToggler] = useState(false);

    return (
        <div>
            <div class="container">
                <h1>Linux Finance Ticker</h1>
                <p class="spaced">Welcome to the Linux Finance Ticker, an open-source tool tailored to provide the Linux Finance Discord community with at-a-glance financial insights directly on their desktop. This application aims to streamline the process by offering real-time financial data and news updates with minimalistic UI.</p>

                <h2>Purpose</h2>
                <p class="spaced">The purpose of this application is to offer users a simple and straightforward way to access financial data and news updates. It aims to provide essential information without overwhelming users with unnecessary features.</p>

                <h2>Empowering the Linux Finance Discord Community</h2>
                <p class="spaced">Designed with the needs of the Linux Finance Discord community in mind, this application serves as a powerful resource for both seasoned investors and newcomers alike. By seamlessly integrating with desktop environments, users can effortlessly access vital financial information without disrupting their workflow. Whether you're actively engaged in trading discussions or simply seeking to stay informed, this tool ensures that crucial market updates are always within reach.</p>

                <h2>Join the Community</h2>
                <p class="spaced">Experience the convenience and utility of the Finance Ticker App by joining the Linux Finance Discord community today. Empower yourself with real-time financial data and news updates, and engage with fellow enthusiasts in meaningful discussions about the ever-evolving world of finance. Together, let's embrace the power of information and stay ahead of the curve with this innovative tool built for the <a href="https://discord.gg/7MVCjysw">Linux Finance Discord community</a>.</p>
                
                <h2>Screenshots</h2>
                
                <ol class="HorizontalList">
                    <li class="ListItemThumb">
                        <img class="ScreenshotThumbSmall" src="/screenshots/Screenshot 1.png" onClick={() => setToggler(!toggler)}></img>
                    </li>
                    <li class="ListItemThumb">
                        <img class="ScreenshotThumb" src="/screenshots/Screenshot 2.png" onClick={() => setToggler(!toggler)}></img>
                    </li>
                    <li class="ListItemThumb">
                        <img class="ScreenshotThumb" src="/screenshots/Screenshot 3.png" onClick={() => setToggler(!toggler)}></img>
                    </li>
                </ol>
                
                <FsLightbox
                    toggler={toggler}
                    sources={[
                        '/screenshots/Screenshot 1.png',
                        '/screenshots/Screenshot 2.png',
                        '/screenshots/Screenshot 3.png',
				]}
			    />

                <h2>Installation and Usage Instructions</h2>
                <h3>Ubuntu and Mint</h3>
                <p class="spaced">Download install the .deb file: <a href="/releases/LinuxFinanceTicker.deb">LinuxFinanceTicker.deb</a></p>
                
                <h3>Other distros</h3>
                <p class="tight">Use nativefier to create a stand alone app for your platform</p>
                <p class="tight">See instructions at https://github.com/nativefier/nativefier</p>
                <p class="spaced">Use the following command to create the app <code>nativefier --name "Linux Finance Ticker" --platform linux --arch x64 --always-on-top --max-width 1024 --max-height 160 --browserwindow-options '&#123;"titleBarStyle":"hidden" &#125;' https://linux-finance-ticker.online/ticker</code></p>
       
                <h3>Web</h3>
                <p class="tight">Or use the application directly from your browser by going to <a href="/ticker/">https://linux-finance-ticker.online/ticker/</a></p>
                <p class="spaced">This has the downside however that it interferes with your normal browser operations</p>

            </div>
        </div>
    );
}

export default Home;