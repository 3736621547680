import './App.css';
import MainWindow from './MainWindow';
import Home from './Home';
import {NextUIProvider} from "@nextui-org/react";
import {
  createBrowserRouter,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";

function App() {
  const navigate = useNavigate();

  return (
    <NextUIProvider navigate={navigate}>
      <Routes>
        <Route path="/ticker" element={<MainWindow />} />
        <Route path="/" element={<Home />} />
      </Routes>
    </NextUIProvider>
  );
}

export default App;
